import React from 'react';

import MainNavigation from '../modules/MainNavigation';

import ContentWrapper from '../components/ContentWrapper';
import CompanyInfo from '../components/CompanyInfo';

export default ({ location }) => (
  <ContentWrapper withBorders>
    <CompanyInfo />

    <MainNavigation path={location.pathname} selectedItems={[]} />
  </ContentWrapper>
);
